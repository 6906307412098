.video-section-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.video-section-container #videos {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  /* max-width: 764px; */
  position: relative;
  width: calc(100% - 320px);
  /* transition: all 500ms; */
  height: 420px;
  /* width: 520px; */
  height: 100%;
}

.video-section-container #videos .onScreen-buttons {
  position: absolute;
  bottom: 20px;
  left: 50%;
  right: 50%;
}

.video-section-container .telemed-side-pannel {
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 1rem;
  flex: 0 0 320px;
}

.video-section-container #videos .onScreen-buttons {
  display: flex;
  justify-content: center;
}

.video-section-container #videos .onScreen-buttons.small {
  bottom: -40px;
}

@media only screen and (max-width: 937px) {
  .video-section-container #videos .onScreen-buttons {
    bottom: -40px;
  }
}

.video-section-container #videos .onScreen-buttons > * {
  margin-right: 5px;
}

.video-section-container #videos .onScreen-buttons button {
  outline: none;
}

.video-section-container #videos .onScreen-buttons .webcam-off-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-section-container #videos .onScreen-buttons .webcam-off-button-wrapper .diagonal-line {
  background: linear-gradient(135deg, transparent calc(50% - 1px), #fff 50%, transparent calc(50% + 1px));
  height: 16px;
  width: 16px;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.video-section-container .localVideo-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.video-section-container .localVideo {
  border-radius: 8px;
  /* height: 420px; */
  height: 100%;
  transition: all 500ms;
  position: relative;
  width: 100%;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  background-color: #000;
}

.video-section-container .remoteVideo {
  border-radius: 8px;
  /* height: 420px; */
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  background-color: #000;
}

.video-section-container .localVideo.joined {
  height: 100px;
  width: 150px;
  margin-bottom: 20px;
  margin-right: 20px;
  z-index: 2;
}

.video-section-container .remote-mic-icon-wrapper {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
}

.video-section-container .remote-mic-icon-wrapper .anticon {
  color: #e3e3e3;
  padding: 10px;
  border-radius: 50%;
  background-color: #555;
}

.video-section-container .full-screen-button-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.video-section-container .localVideo-wrapper .webcam-off {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-section-container .localVideo-wrapper .webcam-off.joined {
  z-index: 2;
  bottom: 54px;
  right: 79px;
  left: auto;
  top: auto;
}

.video-section-container .remoteVideo-wrapper .webcam-off {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
}