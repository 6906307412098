.relogin-modal-modal-body-wrapper {
  zoom: 1;
}

.relogin-modal-modal-body-wrapper::before, .relogin-modal-modal-body-wrapper::after {
  display: table;
  content: '';
}

.relogin-modal-modal-body > .anticon {
  color: #faad14;
  float: left;
  margin-right: 16px;
  font-size: 22px;
}

.relogin-modal-modal-body .relogin-modal-modal-title {
  display: block;
  overflow: hidden;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

.relogin-modal-modal-body > .anticon + .relogin-modal-modal-title + .relogin-modal-modal-content {
  margin-left: 38px;
}

.relogin-modal-modal-body .relogin-modal-modal-content {
  margin-top: 8px;
  color: rgba(0,0,0,.65);
  font-size: 14px;
}

.relogin-modal-modal-btns {
  float: right;
  margin-top: 24px;
}

.relogin-modal-modal-btns button:hover {
  opacity: 0.8;
}

.relogin-modal-modal-btns button:disabled {
  background-color: initial !important;
  border-color: initial !important;
}

.relogin-modal-modal-body-wrapper::after {
  clear: both;
}