.telemed-button-container {
  position: fixed;
  bottom: -3px;
  right: 86px;
  z-index: 1500;
}

@media only screen and (min-width: 601px) {
  .telemed-modal-wrapper {
    pointer-events: none;
  }
}

.telemed-modal-wrapper .telemed-modal-container {
  display: flex;
  position: relative;
  /* height: 420px; */
  height: 100%;
}

.telemed-modal-wrapper .custom-close-button-wrapper {
  display: flex;
  position: absolute;
  top: 12px;
  right: 0;
}

/* .telemed-modal-wrapper .custom-close-button-wrapper button:first-child {
  margin-right: 5px;
} */

.telemed-modal-wrapper .custom-close-button {
  inset-inline-end: 12px;
  z-index: 1010;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
}

.telemed-modal-wrapper .custom-close-button.minus .ant-btn-icon {
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .telemed-modal-wrapper .ant-modal {
    width: 100% !important;
  }

  /* Height was either -16px or 86px */
  /* .telemed-modal-wrapper .ant-modal-content {
    height: calc(100vh - 86px); 
  } */

  .telemed-modal-wrapper .ant-modal-body {
    height: calc(100% - 32px);
  }

  .telemed-modal-wrapper .telemed-modal-container {
    height: 100%;
  }

  .telemed-modal-wrapper #videos {
    height: 100%;
  }

  .telemed-modal-wrapper .video-section-container .localVideo:not(.localVideo.joined) {
    height: 100%;
  }

  .telemed-modal-wrapper .video-section-container .remoteVideo {
    height: 100%;
  }
}

.telemedModal-side-pannel {
  padding-left: 24px;
  flex: 0 0 320px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.telemedModal-side-pannel.smallScreenMode {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
}