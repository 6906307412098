.main-container {
  position: absolute;
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  top: 50px;
  bottom: 50px;
  left: 50px;
  right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  animation: pulsePageLoading 1s infinite;
}

/* @keyframes pulsePageLoading {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
} */

@keyframes pulsePageLoading {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
