.telemed-chat-drawer .ant-drawer-content-wrapper {
  box-shadow: none;
  width: 100% !important;
  height: 100% !important;
}

.telemed-chat-drawer .ant-drawer-content {
  background: transparent;
}

.rcw-message > .rcw-response .chat-user-name {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: -5px;
  color: #333;
}

.rcw-message > .rcw-response .chat-user-name {
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: -5px;
  color: #333;
}

.rcw-message p {
  font-size: 14px;
}